* {
    
}

/*
    WHAT STYLINGS SHOULD BE IN HERE:
    - Positioning
    - Structure
    - Dimension sizes
    - Paddings and Margins
    - Grids and flexboxes
    - Rounded corners
    - overflow


    WHAT STYLINGS SHOUD NOT BE IN HERE: 
    - Colors
    - Font styles
    - Borders
    - Hover appearance
*/



*{
    --page-height-letter: 66rem;
    --page-width-letter: 51rem;

    --max-container-width: 60rem;
    --min-container-width: 20rem;

    --max-container-height: 60rem;
    --min-container-height: 20rem;
}

.rounded-corner{
    border-radius: calc(0.5 * var(--SDsize));
}

.large-container{
    padding: calc(2 * var(--SDsize));
}

.margin-right-1{
    margin-right: calc(1 * var(--SDsize));
}



/*  COMPONENT ****************************** [ AppIconText ] ****************************** */

.AppIconText{
    display: flex;
    gap: var(--SDsize);

    align-items: center;
}
.AppIconText .icon{
    height: calc(2 * var(--SDsize));
}



/*  COMPONENT ****************************** [ Center ] ****************************** */

.Center{
    display: flex;
    align-items: center;
    justify-content: center;
}



/*  COMPONENT ****************************** [ DocPage ] ****************************** */

.DocPage{
    padding: calc(2 * var(--SDsize));
    display: flex;
    justify-content: center;
}

.DocPage textarea{
    border-radius: 5px;
    --page-margin: calc(5 * var(--SDsize));
    padding: var(--page-margin);
    width: calc(var(--page-width-letter) - var(--page-margin) * 2);
    height: calc(var(--page-height-letter) - var(--page-margin) * 2);;   /*  THIS IS THE SIZE OF 11 inches*/

    font-size: 1rem; /* 1 rem is used here because SDsize is only for spacing, positioning and shape sizing*/
}



/*  COMPONENT ****************************** [ Grid ] ****************************** */

.Grid{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(20rem, 100%), 1fr));

    grid-auto-rows: auto;
    gap: var(--SDsize); 
}



/*  COMPONENT ****************************** [ TeleprompterPageContent ] ****************************** */

.TeleprompterPageContent{
    height: calc(100vh - (13 * var(--SDsize)));
    overflow-x: hidden;
    overflow-y: scroll;

    -ms-overflow-style: none; /* for Edge */
    scrollbar-width: none; /* for Firefox */
}
.DocPage .TeleprompterPageContent::-webkit-scrollbar {
    display: none;
  }



/*  COMPONENT ****************************** [ IconTextButton ] ****************************** */

.IconTextButton{
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(0.5 * var(--SDsize));
}
.IconTextButton.rounded-corner{
    padding: var(--SDsize);
    padding-top: 0;
    padding-bottom: 0;
}
.IconTextButton.round-3sd{
    --k: calc(3 * var(--SDsize));
    
    width: var(--k);
    height: var(--k);
    border-radius: calc(2 * var(--k));
}



/*  COMPONENT ****************************** [ ImageTextCard ] ****************************** */

.ImageTextCard{
    position: relative;

    padding: calc(1 * var(--SDsize));
    border-radius: calc(1 * var(--SDsize));
    min-width: calc(5 * var(--SDsize));
    min-height: calc(10 * var(--SDsize));

    cursor: pointer;
    overflow: hidden;
}

.ImageTextCard .title{
    position: absolute;
    font-weight: 700;
}

.ImageTextCard > img{
    position: absolute;
    --img-horizontal-pad: calc(3 * var(--SDsize));

    top: calc(2.5 * var(--SDsize));
    left: var(--img-horizontal-pad);

    height: calc(100%);
    width: calc(100% + -2 * var(--img-horizontal-pad));;

    transition-property: top, left, width, height;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.ImageTextCard:hover > img{
    --enlarge-add: calc(3 * var(--SDsize));

    position: absolute;

    top: calc(2.5 * var(--SDsize) - var(--enlarge-add));
    left: calc(var(--img-horizontal-pad) - var(--enlarge-add));

    height: calc(100% + 2 * var(--enlarge-add));
    width: calc(100% + -2 * var(--img-horizontal-pad) + 2 * var(--enlarge-add));
}



/*  COMPONENT ****************************** [ Modal ] ****************************** */

.Modal{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;


    display: flex;
    justify-content: center;
    align-items: center;
}

.Modal .large-container{
    position: relative;
    display: flex;
    flex-direction: column;

    width: 60rem;
    max-width: min(var(--max-container-width), 60vw);
    min-width: var(--min-container-width);
    max-height: 75vh;

    border-radius: calc(1 * var(--SDsize));
    padding: calc(3 * var(--SDsize));
    gap: calc(2 * var(--SDsize));
}

.Modal .large-container .body{
    overflow-y: scroll;
}



.Modal .header .left{
    display: flex;
    align-items: center;

    font-weight: 700;
    font-size: x-large;
}



/*  COMPONENT ****************************** [ SplitContent ] ****************************** */

.SplitContent, .SplitContent .left, .SplitContent .right{
    display: flex;
}
.SplitContent{
    gap: calc(var(--SDsize));
    justify-content: space-between;
}



/*  COMPONENT ****************************** [ TeleprompterRibbon ] ****************************** */

.TeleprompterRibbon{
    background-color: white; /* move this somewhere soon */
    padding: calc(2 * var(--SDsize));
    padding-top: var(--SDsize);
    padding-bottom: 0;
}

.TeleprompterRibbon .Center{
    padding: calc(var(--SDsize));
    padding-top: calc(0.5 * var(--SDsize));
}

.TeleprompterRibbon .TrainTabs{
    width:  calc(var(--page-width-letter) - var(--SDsize));

    border-radius: calc(var(--SDsize));
}



/*  COMPONENT ****************************** [ TextOverlay ] ****************************** */

.TextOverlay{
    display: flex;
    position: absolute;
    top: 0;
    left: 0;

    --padding: calc(1 * var(--SDsize));
    padding: var(--padding);
    height: calc(100% - 2 * var(--padding));
    width: calc(100% - 2 * var(--padding));

    align-items: center;

    font-size: 0.8rem;
}



/*  COMPONENT ****************************** [ TrainTabs ] ****************************** */

.TrainTabs{
    display: flex;
    padding: calc(0.5 * var(--SDsize));
    gap: calc(0.5 * var(--SDsize));
}

.TrainTabs .tab{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: var(--SDsize);
    padding-bottom: var(--SDsize);
    width: calc(10 * var(--SDsize));

    border-radius: calc(0.5 * var(--SDsize));

    cursor: pointer;
    font-style: normal;

}