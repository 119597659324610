.fullscreen-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    /* background-color: #ffffff; */
    /* display: flex; */
    justify-content: center;
    align-items: center;
    overflow: auto;
  }

  .text-container {
    padding: 10px;
    margin-bottom: 60px; /* Increase the margin-bottom to provide space for the button container */
  }
  
  .button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  
  .icon-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom: 5px;
    padding: 10px 0;
  }

  .container{
    margin: 0 5px;
    z-index: 999;
  }

  .icon-button {
    margin: 0 8px; /* Adjust the button spacing as needed */
    z-index: 1;
    width: 40px;
    height: 40px;
    padding: 5px;
  }
  .icon-button1 {
    margin: 0 8px; /* Adjust the button spacing as needed */
    z-index: 1;
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: white;
    border-radius: 20px;
  }


  .icon-button:hover {
    background-color: hsla(0, 0%, 100%, 0.262);
    border-radius: 20px;
    padding: auto; 
  }
  .icon-button1:hover {
    background-color: hsla(0, 0%, 100%, 0.262);
    border-radius: 20px;
    padding: auto; 
  }


  .icon-background{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: hsla(0, 0%, 100%, 0.521);
    width: 500px;
    height: 65px;
    border-radius: 20px;
  }