

/*
    WHAT STYLINGS SHOUD BE IN HERE: 
    - Colors
    - Font styles
    - Borders
    - Hover appearance


    WHAT STYLINGS SHOULD NOT BE IN HERE:
    - Positioning
    - Structure
    - Dimension sizes
    - Paddings and Margins
    - Grids and flexboxes
    - Rounded corners
    - overflow
*/


@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
@import url('https://fonts.googleapis.com/css2?family=Yantramanav:wght@300;400;500;700;900&display=swap');

/* MAIN THEME */

body, input, textarea{
    /*  Default styling */
    color: hsl(0, 0%, 30%);
    font-family: 'Inter', sans-serif;
    
    /*  Global variable stylings */
    --SDsize: 1rem;
    --accent-color: #4d21ed;
    --accent-color-1: hsl(253, 86%, 66%);
    --accent-color-3: hsl(253, 80%, 97%);

    /*  Colors with opacity*/
    --accent-color-opaque-50: hsl(253, 86%, 53%, 0.5);
    --accent-color-opaque-05: hsl(253, 86%, 53%, 0.05);

    --gray0: hsl(0, 0%, 98%);
    --gray1: hsl(0, 0%, 95%);
    --gray2: hsl(0, 0%, 90%);
    --gray3: hsl(0, 0%, 85%);
    --gray4: hsl(0, 0%, 80%);

    --gray8: hsl(0, 0%, 60%);
}

*{
    --docPage-bg: whitesmoke;
}

.TeleprompterRibbon{
    font-style: oblique;
    font-weight: 500; 
}

.DocPage{
    background-color: var(--docPage-bg);
}

.DocPage textarea{
    background-color: white;
    font-style: normal;
}

.TeleprompterPageContent{
    background-color: var(--docPage-bg);
}

.TrainTabs{
    background-color: var(--docPage-bg);
}

.TrainTabs .tab{
    font-style: normal;
}

/* add background color of white to the selected tab */
.TrainTabs .highlight-1 {
    background-color: white;
}





.highlight-1{
    background-color: whitesmoke;
    color: var(--accent-color);
}

.highlight-2{
    background-color: var(--accent-color);
    color: white;
}





.white{
    background-color: white;
}

.gray1{
    background-color: var(--gray1);
}

.opaque-black-50{
    background-color: hsla(0, 0%, 0%, 0.5);
}
.opaque-white-50{
    background-color: hsla(0, 0%, 100%, 0.5);
}

.opque-accent-50{
    background-color: var(--accent-color-opaque-50);
}

.opque-accent-05{
    background-color: var(--accent-color-opaque-05);
}



.hover-gray{
    transition-property: background-color;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hover-gray:hover{
    background-color: var(--gray2);
}

.hover-background-highlight-light:hover{
    background-color: var(--accent-color-3);
}

.hover-content-highlight{
    transition-property: color;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hover-content-highlight:hover{
    color: var(--accent-color);
}

.hover-highlight-2:hover{
    background-color: var(--accent-color-1);
    color: white;
}

.appear-on-hover{
    opacity: 0;

    transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.appear-on-hover:hover{
    opacity: 1;
}





.shadow-large {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 12px 40px 0 rgba(0, 0, 0, 0.06);
  }
  


.no-appearance{
    border: none;
    outline: none;
    resize: none;
    background-color: transparent;
}



.pointer{
    cursor: pointer;
}

/* style of the drawer and its contents */
.drawer-style {
    margin: 15px;
}

.settings-text {
    margin: 2px;
    margin-bottom: 8px;
}

.subtext {
    color: grey;
}

.selector-box {
    border-radius: 5px;
    height: 30px;
    border-color: 1px solid grey;
}

.input-box {
    border-radius: 5px;
    height: 27px;
    width: 60px;
    border-color: 1px solid grey;
}

.reset-button {
    height: 35px;
    background-color: white;
    border: 1px solid grey;
    border-radius: 5px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    color: hsl(253, 86%, 53%);
}

.reset-button:hover {
    background-color: hsl(253, 86%, 53%);
    color: white;
    border: none;
}